exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-career-opportunities-js": () => import("./../../../src/pages/career-opportunities.js" /* webpackChunkName: "component---src-pages-career-opportunities-js" */),
  "component---src-pages-comp-slider-js": () => import("./../../../src/pages/compSlider.js" /* webpackChunkName: "component---src-pages-comp-slider-js" */),
  "component---src-pages-company-profile-js": () => import("./../../../src/pages/company-profile.js" /* webpackChunkName: "component---src-pages-company-profile-js" */),
  "component---src-pages-customer-support-js": () => import("./../../../src/pages/customer-support.js" /* webpackChunkName: "component---src-pages-customer-support-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-honored-help-in-covid-js": () => import("./../../../src/pages/honored-help-in-covid.js" /* webpackChunkName: "component---src-pages-honored-help-in-covid-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-maintainance-js": () => import("./../../../src/pages/maintainance.js" /* webpackChunkName: "component---src-pages-maintainance-js" */),
  "component---src-pages-our-projects-js": () => import("./../../../src/pages/our-projects.js" /* webpackChunkName: "component---src-pages-our-projects-js" */),
  "component---src-pages-properties-js": () => import("./../../../src/pages/properties.js" /* webpackChunkName: "component---src-pages-properties-js" */),
  "component---src-pages-quality-cetificates-js": () => import("./../../../src/pages/quality-cetificates.js" /* webpackChunkName: "component---src-pages-quality-cetificates-js" */),
  "component---src-pages-real-estate-js": () => import("./../../../src/pages/real-estate.js" /* webpackChunkName: "component---src-pages-real-estate-js" */),
  "component---src-pages-red-and-blue-safety-js": () => import("./../../../src/pages/red-and-blue-safety.js" /* webpackChunkName: "component---src-pages-red-and-blue-safety-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-use-script-js": () => import("./../../../src/pages/useScript.js" /* webpackChunkName: "component---src-pages-use-script-js" */)
}

